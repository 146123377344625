// @todo this whole file needs to be refined, for now, keep it flexible and loose,
//   until we can carve this out into component specific JSON param exports.

import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import {DrupalProductNode} from "../types/hygiena-types";

/**
 * Product documentation fields.
 */
export const productDocumentationMediaFields = [
  'field_product_product_manual',
  'field_product_brochure',
  'field_product_product_sheet',
  'field_product_safety_data_sheet',
  'field_product_quick_guide',
  'field_product_certificates',
  'field_product_additional_docs',
];

export const productVariationFields = [
  'field_product_variations',
  'field_product_variations.field_var_quantity',
  'field_product_variations.field_var_reactions',
  'field_product_variations.field_var_volume',
  'field_product_variations.field_var_tests',
  'field_product_variations.field_var_runs',
];

export const productAssociationFields = [
  'field_product_products',
  'field_product_automation',
  'field_product_real_additionals',
  'field_product_equipment',
  'field_product_segment_step',
  'field_product_enrichment',
  'field_product_instruments',
  'field_product_ext_automated',
  'field_product_ext_standard',
  'field_product_ext_throughput_h',
  'field_product_ext_high_quality',
  'field_product_aut_automated',
  'field_product_reg_automated',
  'field_product_reg_high_quality',
  'field_product_reg_h_throughput',
  'field_product_reg_standard',
  'field_product_eq_automated',
  'field_product_eq_high_quality',
  'field_product_eq_h_throughput',
  'field_product_eq_standard',
  'field_product_software',
  'field_product_monitoring_systems',
  'field_product_sample_collection',
];

export const productExtractionFields = [
  'field_product_ext_automated',
  'field_product_ext_standard',
  'field_product_ext_throughput_h',
  'field_product_ext_high_quality',
];

// These fields should only really be loaded on a full product page load.
export const productFullProductFields = [
  'field_product_hero_video.field_media_file',
  'field_product_workflow',
  'field_product_workflow.field_workflow_items',
  'field_product_workflow.field_workflow_items.field_workflow_item_icon',
  'field_product_training_videos.thumbnail',
  'field_product_training_videos.field_video_categories',
  'field_product_training_videos.field_video_thumbnail.image',
  'field_product_training_videos.field_video_thumbnail.bf_image',
  'field_product_podcast.field_podcast_image.image',
  'field_product_podcast.field_podcast_image.bf_image',
  'field_product_podcast.field_media_file',
  'field_product_podcast',
];

// Products loaded via associated products i.e. instruments, automation via an extraction.
export const productExtendedAssociationFields = [
  'field_product_automation',
];

/**
 * Base includes are the fields that will be required on virtually all
 * instances when a product reference needs to load, these are "light"
 * fields viewed in teasers etc.
 */
export const productBaseFields = [
  'field_product_image.image',
  'field_product_image.bf_image',
  'field_product_brand',
  'field_product_class',
  'field_product_class.parent',
  'field_product_product_manual.field_media_file',
  'field_product_product_sheet.field_media_file',
  'field_product_safety_data_sheet.field_media_file',
  'field_product_quick_guide.field_media_file',
  'field_product_certificates.field_media_file',
  'field_product_certificates.field_certificate_type',
  'field_product_test_targets',
  'field_product_testing_method',
];

/**
 * This builds out a list of includes when pulling a product node on its dedicated product page.
 */
export function getNodeProductIncludes(): string[] {
  let includes = [
    'field_product_hero_image.image',
    'field_product_hero_image.bf_image',
    'field_product_category',
    'field_product_additionals',
    'field_product_certs_no_file',
    'field_product_video',
    'field_product_video.thumbnail',
    // @todo some of these fields can be moved to another includes function.
    'field_product_class.field_class_hero_image.image',
    'field_product_class.field_class_hero_image.bf_image',
    'field_product_category.parent.parent.parent',
    'field_product_category.field_product_type_image.image',
    'field_product_category.field_product_type_image.bf_image',
    'field_product_certificates.field_media_file',
    'field_product_certificates.field_certificate_type',
    'field_product_benefits',
    'field_product_benefits.field_benefit_default_benefit',
    'field_product_benefits.field_benefit_document.field_media_file',
    'field_product_benefits.field_benefit_default_benefit',
    'field_product_benefits.field_benefit_default_benefit.field_benefit_icon',
  ];

  includes = includes.concat(productBaseFields);
  includes = includes.concat(productVariationFields);

  // Pull all documentation media.
  for (const media of productDocumentationMediaFields) {
    includes = includes.concat([
      `${media}`,
      `${media}.field_media_file`,
      `${media}.field_document_language`,
      `${media}.field_document_region`,
    ]);
  }

  return includes;
}

/**
 * These includes are for full product page loads.
 */
export function getFullPageNodeProductIncludes() {
  let includes = getNodeProductIncludes();
  includes = includes.concat(productFullProductFields);
  for (const media of productDocumentationMediaFields) {
    includes = includes.concat([
      `${media}.field_document_categories`,
      `${media}.field_document_language`,
      `${media}.field_document_region`,
    ]);
  }
  return includes;
}

/**
 * Product associations sometimes have tested products i.e. instruments, equipment etc
 * this helps to load them in also.
 */
export function getProductAssociationIncludes() {
  let includes = getNodeProductIncludes();
  return includes;
}

/**
 * Products don't need to be loaded in to such a heavy degree as on actual product pages,
 * this adds a stripped version of includes.
 *
 * @param prefix
 *   Optionally prefix the includes with another field, for use with paragraphs and other content types.
 *
 * @todo break all of these includes up, from a kind of low priority to high priority
 *   order, so we're not redeclaring.
 */
export function getProductListingIncludes(prefix: string = "") {
  let includes = productBaseFields;
  // Pull all documentation media.
  for (const media of productDocumentationMediaFields) {
    includes = includes.concat([
      `${media}`,
      `${media}.field_media_file`,
      `${media}.field_document_language`,
      `${media}.field_document_region`,
    ]);
  }
  includes = includes.concat(productVariationFields);
  if (prefix) {
    return includes.map(item => `${prefix}.${item}`);
  }
  includes.push('field_product_certs_no_file');
  return includes;
}

/**
 * Gets the required fields and includes for building out a product
 * teaser listing; used on Industry, Product Type/Name and Product Class pages,
 * among others.
 */
export function getProductTeaserListingParams(): DrupalJsonApiParams {
  const productFields = productBaseFields.concat([
    'id',
    'status',
    'drupal_internal__nid',
    'title',
    'body',
    'field_product_image',
    'field_product_display_title',
    'field_product_variations',
    'field_product_multiplex_kit',
    'path',
  ]).concat(productDocumentationMediaFields);
  return new DrupalJsonApiParams()
    .addFields('node--product', productFields)
    .addFilter('status', '1')
    .addInclude(getProductListingIncludes());
}

/**
 * Returns params required for related products.
 *
 * @param productIds
 */
export function getRelatedProductParams(productIds?: string[]): DrupalJsonApiParams {
  return new DrupalJsonApiParams()
    .addSort('title', 'ASC')
    .addFields('node--product', [
      'title',
      'status',
      'drupal_internal__nid',
      'field_product_image',
      'field_product_brand',
      'field_product_category',
      'field_product_class',
      'path'
    ])
    .addFields('taxonomy_term--brand', ['name', 'field_brand_suffix'])
    .addFields('taxonomy_term--product_class', ['name'])
    .addInclude(['field_product_image.image', 'field_product_image.bf_image', 'field_product_brand', 'field_product_class', 'field_product_category.parent'])
    .addFilter('id', productIds, 'IN')
    .addFilter('status', '1')
    .addSort('title', 'ASC');
}

/**
 * Strips out any circular references.
 *
 * @todo it is possible to run a safe json stringify to remove circular dependencies
 *   look into this, it may be a better option.
 *
 * @param product
 */
export function preventProductCircularReferences(product: DrupalProductNode) {
  if (product?.field_product_category?.field_product_type_products?.length) {
    product.field_product_category.field_product_type_products = [];
  }
  if (product?.field_product_class?.parent?.length) {
    for (const parent of product.field_product_class.parent) {
      parent.field_class_products = [];
    }
  }

  // Retain any automated carry-through products from extractions.
  const automatedCarryThrough = {} as {[key: string]: DrupalProductNode[]};
  for (const ext of productExtractionFields) {
    if (product?.[ext]?.field_product_automation?.length) {
      automatedCarryThrough[ext] = product[ext].field_product_automation.map(item => {
        removeCircularAssociations(item);
        return item;
      });
    }
  }

  for (const field of productAssociationFields.concat(['field_product_additionals'])) {
    if (product?.[field]) {
      if (Array.isArray(product[field])) {
        for (const i of product[field]) {
          removeCircularAssociations(i);
        }
      }
      else {
        removeCircularAssociations(product[field]);
      }
    }
  }

  // Add the automated carry-through back to the product.
  for (const ext of productExtractionFields) {
    if (product?.[ext] && automatedCarryThrough[ext]) {
      product[ext].field_product_automation = automatedCarryThrough[ext];
    }
  }
}

/**
 * Remove any circular references from product associations.
 *
 * @todo it is possible to run a safe json stringify to remove circular dependencies
 *   look into this, it may be a better option.
 *
 * @param product
 */
function removeCircularAssociations(product: DrupalProductNode) {
  for (const field of productAssociationFields.concat(['field_product_additionals'])) {
    if (product?.[field]) {
      product[field] = null;
    }
  }
}