import {
  Dictionary,
  DrupalProductNode,
  DrupalSearchApiResponseMeta,
  DrupalSearchApiTerm,
  FacetFilters, IndustrySegmentsTaxonomyTerm
} from "@/types/hygiena-types";
import SectionHeading from "../organisms/section-heading";
import {FormattedText} from "../formatted-text";
import {DynamicProductGrid} from "../organisms/widget--product-grid";
import {Pagination} from "../organisms/widget--pagination";
import {Fragment, RefObject, useContext, useEffect, useState} from "react";
import {DrupalJsonApiParams} from "drupal-jsonapi-params";
import {getProductListingIncludes, productBaseFields, productDocumentationMediaFields} from "../../lib/get-includes";
import {LoadingSpinnerOverlay} from "../atoms/loading-spinner";
import {useRouter} from "next/router";
import {DrupalSearchApiFacet, DrupalTaxonomyTerm} from "next-drupal";
import {FilterResetButton} from "../atoms/reset-button";
import {ImageIcon} from "../atoms/icon";
import {ProductionStepButton} from "./production-step-button";
import {MissingProduct} from "./missing-product";
import {DictionaryContext} from "@/context/dictionary-context";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/shadcn/ui/select";
import classNames from "classnames";
import {PRODUCT_FACET_ORDER} from "@/lib/constants";

/**
 * Gets the parameters for the industry segment products query.
 *
 * @param id
 *   The term uuid for the current industry page.
 * @param offset
 *   The offset to begin from.
 * @param pageLimit
 *   The page limit.
 * @param locale
 *   The active locale.
 * @param defaultLocale
 *   The default locale.
 * @param filters
 *   Optional facet filters.
 */
export function getIndustrySegmentProductParams(id: string, offset: number, pageLimit: number, locale: string, defaultLocale: string, filters?: FacetFilters): DrupalJsonApiParams {

  const productFields = productBaseFields.concat([
    'id',
    'title',
    'body',
    'field_product_multiplex_kit',
    'field_product_image',
    'field_product_class',
    'field_product_category',
    'field_product_certs_no_file',
    'field_product_display_title',
    'field_product_variations',
    'path',
  ]).concat(productDocumentationMediaFields);

  const params = new DrupalJsonApiParams()
    .addFields('node--product', productFields)
    .addInclude(getProductListingIncludes().concat([
      'field_product_category.parent',
      'field_product_class'
    ]))
    .addFilter('status', '1', '=')
    .addFilter('langcode', locale ?? 'en', '=')
    // @todo see if we can improve the way we implement industry segment production steps?
    .addFilter('industry_segment_uuid', id, '=')
    .addPageLimit(pageLimit)
    .addPageOffset(offset)
    .addSort('title', 'ASC')
    .addSort('brand_name', 'ASC');

    if (filters) {
      for (const key of Object.keys(filters)) {
        if (filters[key]) {
          // If we're filtering by segment step, we need to structure the filter as [industry uuid]:[production step uuid]
          if (key === 'field_product_segment_step_uuid') {
            params.addFilter('hygiena_production_step', `${id}:${filters[key]}`, '=');
          }
          else {
            params.addFilter(key, filters[key], '=');
          }
        }
      }
    }

  return params;
}

/**
 * Displays a facet filter.
 *
 * @note this uses the original facet filter data from page build, the meta pulled from subsequent searches is
 *       only used to refine the search and grey out unavailable options.
 *
 * @param facet
 *   The facet.
 * @param updateFilter
 *   The update filter callback.
 * @param originalFacet
 *   The original facet from the original search api response data on page load, to confine results.
 * @param activeFacetValue
 *   The active facet value.
 * @param activeFilters
 *   The active filters.
 * @param ignoreActive
 *   This won't be counted when checking if filter items should be enable or disabled.
 * @constructor
 */
function FacetFilter({facet, updateFilter, originalFacet, activeFacetValue, activeFilters, ignoreActive} : {
  facet: DrupalSearchApiFacet,
  updateFilter: (id: string, value: string|string[]|null) => Promise<void>,
  originalFacet: DrupalSearchApiFacet|undefined,
  activeFacetValue?: string|string[]|undefined,
  activeFilters: FacetFilters,
  ignoreActive?: string[],
}) {
  const filtersClone: FacetFilters = activeFilters;
  if (ignoreActive?.length) {
    for (const key of ignoreActive) {
      delete(filtersClone[key]);
    }
  }
  const filterCount = Object.keys(filtersClone).filter(key => filtersClone?.[key])?.length;

  /**
   * Checks if an option should be disabled or not.
   *
   * @param term
   *   The search api term.
   */
  function isDisabled (term: DrupalSearchApiTerm): boolean|undefined {
    if (filterCount === 1 && filtersClone?.[facet?.path ?? ""]?.length) {
      return false;
    }

    if (facet?.terms?.length) {
      for (const item of facet.terms) {
        if (item.values.value === term.values.value) {
          return false;
        }
      }
    }
    return true;
  }

  return (
    <>
      {originalFacet?.terms?.length ? (
        <Select
            value={activeFacetValue}
            onValueChange={val => updateFilter(facet?.path ?? facet.id, val)}>
          <SelectTrigger className={classNames("border-hygienaGray border rounded-sm text-left font-normal w-auto bg-white")}>
            <SelectValue placeholder={facet.label} />
          </SelectTrigger>
          <SelectContent>
            {originalFacet.terms.map(term => (
              <SelectItem disabled={isDisabled(term)} key={`facet-filter--${facet.id}--${term.values.value}`} value={term.values.value}>{term.values.label}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      ): <></>}
    </>
  )
}

/**
 * Builds out facet filters using Drupal Search API Response meta.
 * @param meta
 *   The original search api response meta.
 * @param activeMeta
 *   The active search api response meta.
 * @param label
 *   The label display next to the filters.
 * @param updateFilter
 *   A callback to update the filter.
 * @param resetFilters
 *   A callback to reset the filters.
 * @param active
 *   True if the filters are active.
 * @param filters
 *   The active filters.
 * @param ignore
 *   Facet paths to ignore, these will not be displayed.
 * @constructor
 */
function FacetFilters({meta, activeMeta, label, updateFilter, resetFilters, active, filters, ignore = []}: {
  meta: DrupalSearchApiResponseMeta|undefined,
  activeMeta: DrupalSearchApiResponseMeta,
  label: string,
  updateFilter: (id: string, value: string|string[]|null) => Promise<void>,
  resetFilters: () => Promise<void>,
  active: boolean,
  filters: FacetFilters,
  ignore: string[],
}) {
  /**
   * Gets the active value for a facet if available.
   *
   * @param facet
   */
  function getActiveValue (facet: DrupalSearchApiFacet|undefined): string|string[] {
    const path = facet?.path ?? "";
    return filters?.[path] ?? ""
  }

  return (
    <div className="flex gap-2 items-center justify-end flex-col md:flex-row">
      <div className="w-full text-left md:w-auto">{label}</div>
      <div className="flex md:gap-2 w-full md:w-auto">
        <div className="flex-grow flex md:gap-1 flex-col md:flex-row">
          {activeMeta.facets && activeMeta.facets.filter(facet => !ignore.includes(facet?.path ?? "")).map(facet => (
            <FacetFilter key={`facet-filter--${facet.id}`}
                         originalFacet={meta?.facets?.filter(item => item?.id === facet?.id)?.[0]}
                         facet={facet}
                         activeFilters={filters}
                         ignoreActive={[""]}
                         activeFacetValue={getActiveValue(facet)}
                         updateFilter={updateFilter}/>
          ))}
        </div>
        <div className="flex items-center justify-center">
          <FilterResetButton callback={resetFilters} enabled={active}/>
        </div>
      </div>
    </div>
  )
}

/**
 * The industry segment product grid with search api filters.
 *
 * @note this uses Search API to fetch Industry Segment products and to provide filtering.
 *
 * @param term
 *   The industry segment term.
 * @param products
 *   The products.
 * @param gridKey
 *   A unique grid key.
 * @param meta
 *   Search API Response meta, containing item count and facets.
 */
export function IndustrySegmentProductGrid({term, products, gridKey, meta, scrollRef, steps}: {
  term: IndustrySegmentsTaxonomyTerm,
  products: DrupalProductNode[],
  gridKey: string,
  scrollRef: RefObject<any>|undefined,
  steps?: DrupalTaxonomyTerm[],
  meta?: DrupalSearchApiResponseMeta,
}): JSX.Element {
  const router = useRouter();
  const t = useContext<Dictionary>(DictionaryContext);
  const [activeProducts, setActiveProducts] = useState<DrupalProductNode[]>(products);
  const [activeMeta, setActiveMeta] = useState<DrupalSearchApiResponseMeta|undefined>(meta);
  const [page, setPage] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState<number>(6);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<FacetFilters>({});
  const [productionStep, setProductionStep] = useState<string>("");
  const activeFilters = Object.keys(filters).filter(key => filters[key]?.length && key !== "field_product_segment_step_uuid")?.length > 0;
  const activeCategory = getActiveCategory();
  const nodeorder: {[key: string]: string}[] = [];

  const chainHeader = term?.field_industry_chain_header ?? t?.all?.testing_solutions_chain_header ?? "";
  if (term?.field_featured_products?.length) {
    for (const item of term.field_featured_products) {
      if (term?.id && !isNaN(term.id)) {
        nodeorder[term.id] = term.id;
      }
    }
  }

  async function searchIndustrySegments(pageLimit: number, page: number, filters: FacetFilters) {
    let productData: DrupalProductNode[];
    let productMeta: DrupalSearchApiResponseMeta;

    const params = getIndustrySegmentProductParams(term.id, page * pageLimit, pageLimit, router.locale ?? "en", router.defaultLocale ?? "en", filters);

    try {
      const response = await fetch(`/api/industry-products?${params.getQueryString()}`, {
        method: 'POST',
        body: JSON.stringify({
          query: params.getQueryString(),
          locale: router.locale,
          defaultLocale: router.defaultLocale,
        }),
      });
      const responseJson = await response.json();

      productData = responseJson?.products ?? [];
      productMeta = responseJson?.meta ?? {};
    }
    catch (error) {
      productData = products ?? [];
      productMeta = meta ?? {};
    }

    if (productMeta?.facets) {
      productMeta.facets = productMeta?.facets.sort((a, b) => {
        if (a?.path === "field_product_category_parent" || b?.label === "field_product_category_parent") return -1;
        return a?.label?.localeCompare(b?.label ?? "") ?? 0;
      });
    }
    setActiveMeta(productMeta);
    setActiveProducts(productData ?? []);
    setLoading(false);
  }

  /**
   * Handler for pagination events.
   */
  async function changePage(page) {
    setLoading(true);
    setPage(page);
    if (scrollRef?.current) scrollRef?.current.scrollIntoView({behavior: 'smooth'})
    await searchIndustrySegments(pageLimit, page, filters);
  }

  /**
   * Updates a filter value and re-fetches the products.
   *
   * @param id
   *   The facet path.
   * @param value
   *   The filter value.
   */
  async function updateFilter(id: string, value: string|string[]|null): Promise<void> {
    setPage(0);
    setLoading(true);
    const newFilters = filters;
    newFilters[id] = value;
    setFilters(newFilters);
    await searchIndustrySegments(pageLimit, 0, newFilters);
  }

  /**
   * Resets the filters.
   */
  async function resetFilters(): Promise<void> {
    setPage(0);
    setLoading(true);
    setFilters({});
    await searchIndustrySegments(pageLimit, 0, {});
  }

  /**
   * Updates the production step.
   *
   * @param id
   *   The production step uuid.
   */
  async function updateProductionStep(id: string): Promise<void> {
    setLoading(true);
    setProductionStep(id);
    const newFilters: FacetFilters = id?.length ? {"field_product_segment_step_uuid": id} : {};
    setFilters(newFilters);
    setPage(0);
    await searchIndustrySegments(pageLimit, 0, newFilters);
  }

  /**
   * Gets the active product category label, if available.
   */
  function getActiveCategory(): string|undefined {
    // @todo Localize.
    if (filters?.field_product_category_parent && meta?.facets?.length) {
      const categoryFacet = meta.facets.filter(facet => facet.path = "field_product_category_parent")?.[0];
      if (categoryFacet?.terms?.length) {
        const categoryValue = categoryFacet.terms.filter(term => term.values.value === filters.field_product_category_parent)?.[0];
        return categoryValue?.values?.label ?? t.all.all_products;
      }
    }
    return  t.all.all_products;
  }

  useEffect(() => {
    setPage(0);
    setFilters({});
    setActiveProducts(products);
    setActiveMeta(meta);
    setProductionStep("");
  }, [products]);

  // Sort the meta so facets are ordered the way we want them.
  const productFacetOrderMap = new Map(PRODUCT_FACET_ORDER.map((id, index) => [id, index]));
  if (meta?.facets) {
    meta.facets = meta.facets.sort((a, b) => {
      const indexA = productFacetOrderMap.get(a.id) ?? PRODUCT_FACET_ORDER.length;
      const indexB = productFacetOrderMap.get(b.id) ?? PRODUCT_FACET_ORDER.length;
      return indexA - indexB;
    }) ?? [];
  }
  if (activeMeta?.facets) {
    activeMeta.facets = activeMeta.facets.sort((a, b) => {
      const indexA = productFacetOrderMap.get(a.id) ?? PRODUCT_FACET_ORDER.length;
      const indexB = productFacetOrderMap.get(b.id) ?? PRODUCT_FACET_ORDER.length;
      return indexA - indexB;
    }) ?? [];
  }


  return (
    <>
      <div className="container mx-auto pt-12 md:pt-24 scroll-m-24" key={`industry-segment-products--container--${gridKey}`} ref={scrollRef}>
        {chainHeader && steps?.length ? (
          <SectionHeading title={chainHeader} headerType={2}>
            {term?.field_industry_chain_text?.processed ? (
              <FormattedText processed={term.field_industry_chain_text.processed}/>
            ): (
              <p>{`${t?.all?.testing_solutions_chain_text ?? ""}`}</p>
            )}
          </SectionHeading>
        ): <></>}
        {/* Filters are taken from the Search API Result meta. */}
        <div className="relative">
          {steps?.length ? (
            <div className="flex flex-row md:flex-wrap gap-4 md:gap-8 justify-evenly mt-16 items-start relative">
              {steps.map((item: DrupalTaxonomyTerm, idx) => (
                <Fragment key={item.id}>
                  <div className="flex items-center gap-16">
                    <div className="flex flex-col">
                      {item?.field_production_step_icon?.name && (
                        <ProductionStepButton icon={item.field_production_step_icon}
                                              title={item?.name ?? ""}
                                              active={item.id === productionStep}
                                              callback={() => {
                                                updateProductionStep(item.id !== productionStep ? item.id : "");
                                              }}/>
                      )}
                      <div className="text-primary text-center font-bold text-xs md:text-2xl max-w-[175px]">{item?.name}</div>
                    </div>
                  </div>
                  {idx !== steps.length -1 && (
                    <div className="top-[45px] relative">
                      <></>
                      <ImageIcon type={"chevron-right"} color={"primary"} height={35} width={35} alt={"Workflow next"} />
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          ): <></>}
          <div className="pt-12 md:pt-24">
            {activeMeta && (
              // @todo localize this.
              <FacetFilters activeMeta={activeMeta} meta={meta} label={`${t.all.filter_products}:`}
                            ignore={[]}
                            updateFilter={updateFilter} resetFilters={resetFilters} active={activeFilters} filters={filters}/>
            )}
            {activeCategory && (
              <h3>{activeCategory}</h3>
            )}
            <DynamicProductGrid nodes={activeProducts} filters={false} nodeorder={nodeorder}/>
            <LoadingSpinnerOverlay loading={loading} fixed={true}/>
          </div>
          <Pagination count={activeMeta?.count ?? 0} page={page} limit={pageLimit} changePage={changePage} radius={5}/>
          <MissingProduct className="max-md:hidden !px-0"/>
          </div>
      </div>
    </>
  )
}

