import {Button} from "../atoms/button";
import classNames from "classnames";
import {useContext} from "react";
import {Dictionary} from "../../types/hygiena-types";
import {DictionaryContext} from "../../context/dictionary-context";

/**
 * Returns the missing product section.
 *
 * @constructor
 */
export function MissingProduct({className}: {className?: string}) {
  const t = useContext<Dictionary>(DictionaryContext)

  return (
    <div className={classNames("container px-6 mx-auto text-center md:text-right", className ?? "")}>
      <>
        {t?.all?.missing_product} <Button href={t?.aliases?.product_finder} invert={true} hover={{color: "primary"}} className="max-md:mt-2 ml-4 btn-product-finder">{t?.all?.go_to_product_finder}</Button>
      </>
    </div>
  );
}