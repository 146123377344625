import {useState} from "react";
import classNames from "classnames";
import {ImageIcon} from "../atoms/icon";

/**
 * Renders a production step icon button.
 *
 * @todo this was pulled from widget--production-step-section.tsx, that file will be removed later.
 *
 * @param icon
 * @param callback
 * @param active
 * @param title
 * @constructor
 */
export function ProductionStepButton ({icon, callback, active, title}) {
  const [hover, setHover] = useState(false);

  return (
    <div className="inline-block mx-auto mb-4 min-h-[55px] min-w-[55px] md:min-h-[100px] md:min-w-[100px]">
      <button onClick={callback}
              aria-label={title}
              role="button"
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              data-step={`${title}`}
              className={classNames(
                "btn-industry-step cursor-pointer hover:bg-primary border-[3px] flex justify-center items-center border-primary rounded-md transition-all duration-500",
                {"bg-primary": active},
              )}>
        <div className="min-h-[60px] min-w-[60px] md:min-h-[100px] md:min-w-[100px] flex items-center justify-center cursor-pointer relative">
          <ImageIcon type={icon.name} className={classNames("absolute duration-200", {"opacity-0": (!hover && !active)})} color={"white"} height={50} width={50} alt={title} style={{objectFit: "cover"}}/>
          <ImageIcon type={icon.name} className={classNames("absolute duration-200", {"opacity-0": (hover || active)})} color={"primary"} height={50} width={50} alt={title} style={{objectFit: "cover"}}/>
        </div>
      </button>
    </div>
  );
}
