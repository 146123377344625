import classNames from "classnames";

/**
 * Adds a red filter button.
 *
 * @param enabled
 * @param callback
 * @constructor
 */
export function FilterResetButton({enabled, callback, className}: {enabled?: boolean, callback: () => void, className?: string}) {
  return (
    <div onClick={callback} className={classNames(
      "reset-filters ml-3 animate-fade-in-slow cursor-pointer opacity-0 transition-opacity duration-300",
      className ?? "",
      {
        "opacity-100": enabled,
        "pointer-events-none": !enabled,
      }
    )}>
      <div className="bg-accent-red h-[30px] w-[30px] rounded-md cursor-pointer relative flex justify-center">
        <div className="bg-white h-[26px] w-[4px] rounded-md absolute left-[13px] top-[2px] rotate-45"/>
        <div className="bg-white h-[26px] w-[4px] rounded-md absolute left-[13px] top-[2px] -rotate-45"/>
      </div>
    </div>
  )
}
